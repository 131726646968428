import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ListItem, ListItemText } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import PropTypes from "prop-types";

import { Avatar } from "@mui/material";
import licenseeLogo from "../../assets/img/icon1.png";
import appLogo from "../../assets/img/Icons2.svg";
import channelLogo from "../../assets/img/Icons4.svg";
import customerLogo from "../../assets/img/Icons7.svg";
import { useNavigate } from "react-router-dom";
import clockIcon from "../../assets/img/clockIcon.svg";
import moment from "moment";
import dataIcon from "../../assets/img/dataIcon.svg";
import { userRoleDispatchContext } from "../../context/serviceContext";

export default function PopUp({ value }) {
  const PRODUCT_WRAP = 1;
  const PRODUCT_MAX = 2;
  const PRODUCT_SWITCH = 3;
  const PRODUCT_REACH = 4;
  const PRODUCT_DIRECT = 5;
  const PRODUCT_CONNECT = 8;
  const PRODUCT_DDIRECT = 7;

  const userRoleDispatchContextVal = useContext(userRoleDispatchContext);
  let navigate = useNavigate();
  const switchName = (value) => {
    return (
      value?.userId ||
      value?.companyId ||
      (value?.applicationId &&
        [
          "#datafree SWITCH",
          "#datafree REACH",
          "#datafree WRAP",
          "#datafree DIRECT",
          "D-Direct",
          "#datafree CONNECT",
        ].includes(value?.productId?.productType))
    );
  };

  const switchDashs = (item) => {
    const userRole = localStorage.getItem("role");
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";
    switch (type) {
      case "BINU":
        if (userRole === "ROLE_ADMIN") {
          return true;
        }
        break;

      case "LICENSEE":
        if (userRole === "ROLE_LICENSEE" || userRole === "ROLE_ADMIN") {
          return true;
        }
        break;

      case "CHANNEL":
        if (
          userRole === "ROLE_LICENSEE" ||
          userRole === "ROLE_ADMIN" ||
          userRole === "ROLE_CHANNEL"
        ) {
          return true;
        }
        break;

      case "CUSTOMER":
      case "RESELLER":
        if (
          userRole === "ROLE_LICENSEE" ||
          userRole === "ROLE_ADMIN" ||
          userRole === "ROLE_CHANNEL" ||
          userRole === "ROLE_USER"
        ) {
          return true;
        }
        break;

      default:
        return false;
    }
  };
  const switchUser = (value) => {
    switch (value?.companyType) {
      case "LICENSEE":
        localStorage.setItem(
          "parentCompId",
          value?.parentCompany?.[0]?.companyId
        );
        break;

      case "CHANNEL":
        localStorage.setItem(
          "parentCompId",
          value?.parentCompany?.[1]?.companyId
        );
        break;

      case "CUSTOMER":
        localStorage.setItem(
          "parentCompId",
          value?.parentCompany?.[2]?.companyId
        );
        break;

      default:
        break;
    }

    localStorage.setItem("userRole", value?.role);
    localStorage.setItem("selectType", value?.role);
    userRoleDispatchContextVal(value?.role);
    navigate("/user-details/", { state: { clickedUserId: value?.userId } });
  };
  const switchApplication = (value) => {
    localStorage.setItem("userRole", value?.role);
    if (
      value?.productId?.productType === "#datafree SWITCH" ||
      value?.productId?.productType === "#datafree REACH" ||
      value?.productId?.productType === "#datafree WRAP" ||
      value?.productId?.productType === "#datafree DIRECT" ||
      value?.productId?.productType === "D-Direct" ||
      value?.productId?.productType === "#datafree CONNECT"
    ) {
      navigate("/application-details/", {
        state: { clickedAppId: value?.applicationId },
      });
    }
  };
  const switchDash = (item) => {
    const userRole = localStorage.getItem("role");
    const type = item?.type
      ? item?.type
      : item?.companyType
      ? item?.companyType
      : "";
    if (type === "BINU" && userRole === "ROLE_ADMIN") {
      navigate("/");
    } else if (
      type === "LICENSEE" &&
      (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE")
    ) {
      if (userRole === "ROLE_ADMIN") {
        localStorage.setItem("licenseeId", item?.companyId);
        navigate("/licensee", {
          state: { clickedCompId: item?.companyId },
        });
      } else {
        navigate("/");
      }
    } else if (
      type === "CHANNEL" &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL")
    ) {
      if (userRole === "ROLE_ADMIN" || userRole === "ROLE_LICENSEE") {
        localStorage.setItem("channelId", item?.companyId);
        navigate("/channel", {
          state: { clickedCompId: item?.companyId },
        });
      } else {
        navigate("/");
      }
    } else if (
      (type === "CUSTOMER" || type === "RESELLER") &&
      (userRole === "ROLE_ADMIN" ||
        userRole === "ROLE_LICENSEE" ||
        userRole === "ROLE_CHANNEL" ||
        userRole === "ROLE_USER")
    ) {
      if (userRole === "ROLE_USER") {
        navigate("/");
      } else {
        localStorage.setItem("customerId", item?.companyId);
        navigate("/customer", {
          state: { clickedCompId: item?.companyId },
        });
      }
    }
  };

  const imageUrl = (item) => {
    switch (item?.role) {
      case "channel":
        return channelLogo;

      case "licensee":
        return licenseeLogo;

      case "user":
        return customerLogo;

      default:
        return appLogo;
    }
  };

  const userDetails = (item) => {
    if (item?.companyId) {
      return (
        <>
          <span
            style={{
              color: "#5A5656",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            {item?.companyType === "CUSTOMER" ||
            item?.companyType === "RESELLER"
              ? "Customer"
              : item?.companyType.toLowerCase()}
          </span>
          {item?.companyId}
          {` | ${item?.status}`}
        </>
      );
    } else if (item?.applicationId) {
      switch (item?.productId.productId) {
        case PRODUCT_DIRECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  S-Direct App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Endpoint: ${item?.endPoint}`}</span>
              <span>{`Datafree Domain: ${item?.domain}`}</span>
              {item.port.length > 0
                ? item?.port.map((port, index) =>
                    index + 1 === item?.port.length
                      ? `${port?.portProtocol?.protocol} ${
                          port?.portStart !== null ? port?.portStart + "," : ""
                        }  ${port?.portEnd !== null ? port?.portEnd : ""}`
                      : `${port?.portProtocol?.protocol} ${
                          port?.portStart !== null ? port?.portStart + "," : ""
                        }  ${port?.portEnd !== null ? port?.portEnd : ""} | `
                  )
                : ""}
              <span style={{ marginTop: "8px" }}>
                {item.deployment.length > 0
                  ? item?.deployment.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_DDIRECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  D-Direct App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Client domain: ${item?.clientDomain}`}</span>
              <span>{`Endpoint: ${item?.endpoint}`}</span>
              <span>{`Datafree Domain: ${item?.domain}`}</span>

              <span style={{ marginTop: "8px" }}>
                {item.deployment.length > 0
                  ? item?.deployment.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_CONNECT:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  Connect App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span
                style={{ marginTop: "8px" }}
              >{`ID: ${item?.applicationId}`}</span>
              <span>{`Authentication: ${item?.authentication}`}</span>
              <span style={{ marginBottom: "8px" }}>{`No. of Apps: ${
                item?.appsCount ? item?.appsCount : "N/A"
              }`}</span>
              <span style={{ marginTop: "8px" }}>
                {item.deployment.length > 0
                  ? item?.deployment.map((apps, index) => (
                      <span key={index}>
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ))
                  : ""}
                {dataUsage(item)}
              </span>
            </>
          );
        case PRODUCT_WRAP:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  Wrap App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              {item.deployment.length > 0
                ? item?.deployment.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_MAX:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  Max App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              {item.deployment.length > 0
                ? item?.deployment.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Production </span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500 }}>Development</span> ID:{" "}
                        {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_REACH:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "12px",
                  }}
                >
                  Reach App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span>{`${item.startUrl ? item.startUrl : "N/A"} | ${
                item.domain ? item.domain : "N/A"
              }`}</span>
              {item.deployment.length > 0
                ? item?.deployment.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500, fontSize: "12px" }}>
                          Production{" "}
                        </span>{" "}
                        ID: {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500, fontSize: "12px" }}>
                          Development
                        </span>{" "}
                        ID: {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        case PRODUCT_SWITCH:
          return (
            <>
              <span>
                <span
                  style={{
                    color: "#5A5656",
                    textTransform: "capitalize",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  Switch App
                </span>
                <i>{` - ${item?.status}`}</i>
              </span>
              <span>{`${item.domain ? item.domain : "N/A"}`}</span>
              {item.deployment.length > 0
                ? item?.deployment.map((apps, index) =>
                    apps?.deploymentType === "PROD" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500, fontSize: "12px" }}>
                          Production{" "}
                        </span>{" "}
                        ID: {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                        {dataUsage(item)}
                      </span>
                    ) : apps?.deploymentType === "DEV" ? (
                      <span style={{ marginTop: "8px" }} key={index}>
                        <span style={{ fontWeight: 500, fontSize: "12px" }}>
                          Development
                        </span>{" "}
                        ID: {apps?.appId}
                        {updateActivity(apps?.lastUpdateDate)}
                      </span>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </>
          );
        default:
          return "";
      }
    } else if (item?.userId) {
      return (
        <>
          <span>
            <span
              style={{
                color: "#5A5656",
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              {" "}
              {getType(item)}
            </span>
            {` | ${item?.verified === 1 ? "Verified" : "Not Verified"}`}
          </span>
        </>
      );
    }
  };

  const dataUsage = (item) => {
    if (item?.applicationId) {
      return (
        <Box
          component="span"
          sx={{
            color: "#5A5656",
            fontWeight: "300",
            fontSize: "13px",
            display: "flex",
          }}
        >
          <img src={dataIcon} alt=""></img>
          <span style={{ marginTop: "1px", marginLeft: "5px" }}>
            {item.prodDataUsage28Days
              ? item.prodDataUsage28Days !== null
                ? item?.prodDataUsage28Days + " MB"
                : " N/A"
              : "N/A"}
          </span>
        </Box>
      );
    }
  };

  const getType = (item) => {
    switch (item?.role) {
      case "user":
      case "reseller":
        return "Customer User";
      case "admin":
        return "Admin";
      case "licensee":
        return "Licensee User";
      case "channel":
      case "accountmanager":
        return "Channel User";
      default:
        return item?.role.toLowerCase() + " User";
    }
  };

  const updateActivity = (item) => {
    return (
      <>
        <Box
          component="span"
          sx={{
            color: "#5A5656",
            fontWeight: "300",
            fontSize: "13px",
            display: "flex",
          }}
        >
          <img src={clockIcon} alt=""></img>
          <span style={{ marginTop: "1px", marginLeft: "5px" }}>
            {item ? moment(item).format("YYYY MMM DD hh:mm:ss") : "N/A"}
          </span>
        </Box>
      </>
    );
  };

  const userData = (item) => {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        {item?.parentCompany.map((val, index) => (
          <span
            className={switchDashs(val) ? "pointer-cursor" : "default-cursor"}
            style={{
              width: "100%",
              fontSize: "11px",
              cursor: switchDashs(val) ? "pointer" : "default",
            }}
            onClick={() => switchDash(val)}
            key={index}
          >
            {index !== item?.parentCompany.length - 1
              ? ` ${val?.name} > `
              : ` ${val?.name}`}
          </span>
        ))}
      </div>
    );
  };

  const getCounts = (item) => {
    if (item?.companyId) {
      if (item?.companyType === "LICENSEE") {
        return (
          <>
            <span
              style={{
                color: "#01537A",
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {item?.noOfChannels
                ? `${item?.noOfChannels} Channels`
                : `0 Channels`}
              {item?.noOfPublishers
                ? ` | ${item?.noOfPublishers} Customers`
                : ` | 0 Customers`}
              {item?.noOfApps ? ` | ${item?.noOfApps} Apps` : ` | 0 Apps`}
            </span>
          </>
        );
      } else if (item?.companyType === "CHANNEL") {
        return (
          <>
            <span
              style={{
                color: "#01537A",
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {item?.noOfPublishers
                ? `${item?.noOfPublishers} Customers`
                : `0 Customers`}
              {item?.noOfApps ? ` | ${item?.noOfApps} Apps` : ` | 0 Apps`}
            </span>
          </>
        );
      } else if (
        item?.companyType === "CUSTOMER" ||
        item?.companyType === "RESELLER"
      ) {
        return (
          <>
            <span
              style={{
                color: "#01537A",
                textTransform: "capitalize",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {item?.noOfApps ? `${item?.noOfApps} Apps` : `0 Apps`}
            </span>
          </>
        );
      }
    }
  };

  const getDeploymentTypes = (item) => {
    if (item?.applicationId) {
      return (
        <>
          <span
            style={{
              color: "#01537A",
              textTransform: "capitalize",
              fontWeight: 500,
              fontSize: "14px",
              marginTop: "5px",
            }}
          >
            {item.deployment.length > 0
              ? item?.deployment.map((apps, index) => (
                  <span style={{ marginRight: "15px" }} key={index}>
                    {`${
                      apps?.deploymentType === "PROD"
                        ? "Production"
                        : apps?.deploymentType === "DEV"
                        ? "Development"
                        : ""
                    }`}{" "}
                  </span>
                ))
              : ""}
          </span>
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        margin: "3px",
      }}
    >
      <ListItem
        alignItems="flex-start"
        style={{
          padding: "0",
        }}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              height: 42,
              width: 45,
              mr: 2,
            }}
            alt=" "
            src={imageUrl(value)}
          />
        </ListItemAvatar>
        <ListItemText
          style={{
            color: "#0000008a",
            fontSize: "14px",
            textTransform: "uppercase",
          }}
          fontWeight="500"
          primary={
            <Typography
              sx={{ display: "flex" }}
              variant="body2"
              component="span"
              style={{
                width: "100%",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: "500",
                fontSize: "11px",
                letterSpacing: "0.89px",
                lineHeight: "11px",
              }}
              textTransform="uppercase"
            >
              {userData(value)}
            </Typography>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "flex" }}
                component="label"
                variant="body2"
                style={{
                  color: "#01537A",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "11.34px",
                  paddingTop: "8px",
                }}
                textTransform="uppercase"
              >
                <span
                  style={{
                    cursor: switchName(value) ? "pointer" : "default",
                  }}
                  onClick={() =>
                    value?.userId
                      ? switchUser(value)
                      : value?.applicationId
                      ? switchApplication(value)
                      : switchDash(value)
                  }
                >
                  {value?.name}
                </span>
              </Typography>
              <Typography
                sx={{ display: "flex", flexDirection: "column" }}
                component="span"
                variant="body2"
                color="text.primary"
                textTransform="none"
                style={{
                  color: "#5A5656",
                  fontWeight: "300",
                  fontSize: "14px",
                  marginTop: "5px",
                }}
              >
                {userDetails(value)}
                {value?.email ? <span>{value?.email}</span> : ""}
                {value?.accountManagerEmail ? (
                  <span>{value?.accountManagerEmail}</span>
                ) : (
                  ""
                )}
                {!value?.applicationId ? (
                  <span style={{ margnTop: "8px" }}>
                    {value?.lastActivity
                      ? updateActivity(value?.lastActivity)
                      : null}
                    {value?.lastUpdateDate
                      ? updateActivity(value?.lastUpdateDate)
                      : null}
                  </span>
                ) : (
                  ""
                )}
                {getCounts(value)}
                {getDeploymentTypes(value)}
              </Typography>
            </>
          }
        />
      </ListItem>
    </Box>
  );
}
PopUp.propTypes = {
  value: PropTypes.shape({
    userId: PropTypes.any,
    companyId: PropTypes.any,
    applicationId: PropTypes.any,
    productId: PropTypes.shape({
      productType: PropTypes.any,
    }),
  }).isRequired,
};
